import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import { navItems } from '@/containers/_nav'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Logout = () => import('@/views/pages/Logout')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

import ManageAccountRouter from '@/router/manage-account'
import AccountSettingRouter from '@/router/account-setting'
import SettingReportRouter from '@/router/setting-report'
import WinLoseReportRouter from '@/router/win-lose-report'
import AccountReport from '@/router/account-report'
import RoundSetting from '@/router/setting-round'
import Help from '@/router/help'
import Assistant from '@/router/assistant'

function configRoutes () {
  return [
    {
      path: '/',
      // name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard')
        },
        ManageAccountRouter,
        AccountSettingRouter,
        SettingReportRouter,
        WinLoseReportRouter,
        AccountReport,
        RoundSetting,
        Help,
        Assistant,
        {
          path: '/logout',
          name: 'Logout',
          component: Logout
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {

    const permission = store.state.account?.permission || {}
    const role = store.state.userProfile.role
    const userRole = store.state.userProfile.userRole

    const pemItem = navItems.find((item)=>{
      return item.to === to.path
    })

    const accountRoles = pemItem?.roles || [role]
    const userRoles = pemItem?.userRoles || [userRole]

    if(pemItem !== undefined) {

      /**
       * ตรวจสอบสิทธิ
       */
      let allowPermission = false
      if(userRole === 'Owner') {
        allowPermission = true
      } else
      if(userRole) {
        const priName = pemItem?.pemPrimary || 'none'
        const assPriPermission = permission?.[priName] ?? 'r'

        const navName = pemItem?.permissionName || 'none'
        const assPermission = permission?.[navName] ?? 'r'

        allowPermission = assPriPermission !== 'h' && assPermission !== 'h'
      }

      if(accountRoles.includes(role) && userRoles.includes(userRole) && allowPermission) {
        next()
      }else{
        next({ name: 'Dashboard' })
      }
    }else{
      next()
    }
  }
});

export default router
