export default {
  path: '/account-setting',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'sharing',
      name: 'AccountSettingSharing',
      component: () => import('@/views/account-setting/Sharing')
    },
    {
      path: 'group',
      name: 'AccountSettingGroup',
      component: () => import('@/views/account-setting/Group')
    },
    {
      path: 'payout',
      name: 'AccountSettingPayout',
      component: () => import('@/views/account-setting/Payout')
    },
    {
      path: 'bet',
      name: 'AccountSettingBet',
      component: () => import('@/views/account-setting/Bet')
    },
    {
      path: 'lottoset',
      name: 'AccountSettingLottoset',
      component: () => import('@/views/account-setting/Lottoset')
    }
  ]
}
