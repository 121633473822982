const navs = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'หน้าหลัก',
        permissionName: 'Home',
        to: '/',
        fontIcon: 'fas fa-home icon-circle',
        roles: ['Company', 'Agent'],
        items: []
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายการแทง',
        permissionName: 'BetReport',
        route: '/setting-report',
        fontIcon: 'far fa-file-alt icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'รอผลเดิมพัน',
            permissionName: 'WaitReport',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/wait'
          },
          {
            name: 'ดูของรวม',
            permissionName: 'OverallReport',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/overall'
          },
          {
            name: 'คาดคะเนได้-เสีย',
            permissionName: 'WinLose',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/win-lose'
          },
          // {
          //   name: 'ตรวจสอบยอด',
          //   permissionName: 'WinLose2',
          //   fontIcon: 'fas fa-circle',
          //   to: '/setting-report/win-lose'
          // },
          {
            name: 'ดูของสมาชิก/ประเภท',
            permissionName: 'MemberReport',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/member'
          },
          {
            name: 'รายการโพย/ยกเลิกโพย',
            permissionName: 'TicketReport',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/tickets'
          },
          {
            name: 'เลขอั้น',
            permissionName: 'LimitNumbers',
            fontIcon: 'fas fa-circle',
            to: '/setting-report/limit',
            mTypes: ['single'],
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงานแพ้ชนะ',
        permissionName: 'WinLoseReport',
        route: '/win-lose-report',
        fontIcon: 'fas fa-file-invoice-dollar icon-circle',
        roles: ['Company', 'Agent'],
        items: [{
          name: 'แพ้-ชนะ สุทธิ',
          permissionName: 'MarketWinLose',
          fontIcon: 'fas fa-circle',
          to: '/win-lose-report/market'
        }, {
          name: 'แพ้-ชนะ สมาชิก/ประเภท',
          permissionName: 'MemberTypeWinLose',
          fontIcon: 'fas fa-circle',
          to: '/win-lose-report/member'
        }, {
          name: 'โพยที่ถูกรางวัล',
          permissionName: 'WonTickets',
          fontIcon: 'fas fa-circle',
          to: '/win-lose-report/won-tickets'
        }, {
          name: 'ผลการออกรางวัล',
          permissionName: 'Results',
          fontIcon: 'fas fa-circle',
          to: '/win-lose-report/results'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าหวย',
        permissionName: 'RoundSetting',
        route: '/setting-round',
        fontIcon: 'fas fa-cogs icon-circle',
        roles: ['Company', 'Agent'],
        items: [{
          name: 'ตั้งค่าราคาจ่าย',
          permissionName: 'Payrate',
          fontIcon: 'fas fa-circle',
          roles: ['Company'],
          mTypes: ['single'],
          to: '/setting-round/number-rate'
        }, {
          name: 'ตั้งค่ารับของ',
          permissionName: 'RoundReceive',
          fontIcon: 'fas fa-circle',
          roles: ['Company', 'Agent'],
          mTypes: ['single'],
          to: '/setting-round/receive'
        }, /*{
          name: 'ตั้งค่าหวยชุด',
          fontIcon: 'fas fa-circle',
          roles: ['Company'],
          mTypes: ['lottoset'],
          to: '/setting-round/lottoset'
        },*/ {
          name: 'ตั้งค่ารับของแยกตามชนิด',
          permissionName: 'MarketReceive',
          fontIcon: 'fas fa-circle',
          roles: ['Company', 'Agent'],
          to: '/setting-round/market-receive'
        }, {
          name: 'ประวัติการตั้งค่า',
          permissionName: 'SettingHistory',
          fontIcon: 'fas fa-circle',
          roles: ['Company', 'Agent'],
          to: '/setting-round/history'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'การเงิน',
        permissionName: 'Finance',
        route: '/account-report',
        fontIcon: 'far fa-list-alt icon-circle',
        roles: ['Company', 'Agent'],
        items: [{
          name: 'รายงานการเงิน',
          permissionName: 'FinanceReport',
          fontIcon: 'fas fa-circle',
          to: '/account-report/finance'
        }, {
          name: 'การเงินสมาชิก',
          permissionName: 'FinanceMember',
          fontIcon: 'fas fa-circle',
          to: '/account-report/finance-member'
        }, {
          name: 'ประวัติการเข้าใช้งาน',
          permissionName: 'AccountLog',
          fontIcon: 'fas fa-circle',
          to: '/account-report/log'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการสมาชิก',
        permissionName: 'ManageAccount',
        route: '/manage-account',
        fontIcon: 'fas fa-users icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'เพิ่มเมมเบอร์',
            permissionName: 'CreateMember',
            fontIcon: 'fas fa-circle',
            to: '/manage-account/create/member'
          },
          {
            name: 'เพิ่มเอเย่นต์',
            permissionName: 'CreateAgent',
            fontIcon: 'fas fa-circle',
            to: '/manage-account/create/agent'
          },
          {
            name: 'รายชื่อสมาชิก',
            permissionName: 'MemberSetting',
            fontIcon: 'fas fa-circle',
            to: '/manage-account/member'
          },
          {
            name: 'รายชื่อที่ถูกระงับ',
            permissionName: 'BlockMember',
            fontIcon: 'fas fa-circle',
            to: '/manage-account/block'
          },
          {
            name: 'ตั้งค่าสมาชิกใหม่',
            permissionName: 'NewMemberSetting',
            fontIcon: 'fas fa-circle',
            to: '/manage-account/setting'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าสมาชิก',
        permissionName: 'AccountSetting',
        route: '/account-setting',
        fontIcon: 'fas fa-user-cog icon-circle',
        roles: ['Company', 'Agent'],
        items: [
          {
            name: 'เปิด/ปิด หวย',
            permissionName: 'MemberMarket',
            fontIcon: 'fas fa-circle',
            to: '/account-setting/group'
          },
          {
            name: 'แบ่งหุ้น/เก็บของ',
            permissionName: 'MemberSharing',
            fontIcon: 'fas fa-circle',
            to: '/account-setting/sharing'
          },
          {
            name: 'เปิด/ปิด/ตั้งค่า อัตราจ่าย',
            permissionName: 'MemberPayout',
            fontIcon: 'fas fa-circle',
            to: '/account-setting/payout'
          },
          {
            name: 'ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข',
            permissionName: 'MemberBetLimit',
            fontIcon: 'fas fa-circle',
            to: '/account-setting/bet'
          }/*,
          {
            name: 'ตั้งค่าหวยชุด',
            permissionName: 'MemberLottoSet',
            fontIcon: 'fas fa-circle',
            to: '/account-setting/lottoset'
          }*/
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ผู้ช่วย',
        route: '/assistant',
        fontIcon: 'fas fa-child icon-circle',
        roles: ['Company', 'Agent'],
        userRoles: ['Owner'],
        items: [{
          name: 'รายชื่อผู้ช่วย',
          userRoles: ['Owner'],
          fontIcon: 'fas fa-angle-double-right',
          to: '/assistant/all'
        }, {
          name: 'เพิ่มผู้ช่วย',
          userRoles: ['Owner'],
          fontIcon: 'fas fa-angle-double-right',
          to: '/assistant/create'
        }]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'คู่มือการใช้งาน',
        route: '/help',
        to: '/help',
        fontIcon: 'fas fa-book-open icon-circle',
        roles: ['Company', 'Agent'],
        items: []
      },
      {
        _name: 'CSidebarNavItem',
        name: 'ออกจากระบบ',
        to: '/logout',
        fontIcon: 'fas fa-sign-out-alt icon-circle',
        items: []
      }
    ]
  }
]

export default navs

export const navItems = navs[0]._children.reduce((items, nav)=>{
  if(nav.items.length) {

    const setWithPrimaryItems = nav.items.map((subItem)=>{
      return {
        ...subItem,
        pemPrimary: nav?.permissionName ?? null
      }
    })

    return items.concat(setWithPrimaryItems)
  }else{
    return items
  }
}, [])
