export default {
  path: 'win-lose-report',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'market',
      name: 'RoundWinLoseReport',
      component: () => import('@/views/win-lose-report/MarketWinLose')
    },
    {
      path: 'member',
      name: 'RoundMemberWinLose',
      component: () => import('@/views/win-lose-report/RoundMemberWinLose')
    },
    {
      path: 'won-tickets',
      name: 'RoundWonTickets',
      component: () => import('@/views/win-lose-report/RoundWonTickets')
    },
    {
      path: 'results',
      name: 'MarketResults',
      component: () => import('@/views/win-lose-report/MarketResults')
    }
  ]
}
