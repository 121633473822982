const AppConfig = {
  key: {
    LoggedIn: 'loggedInAgentMetabetAll',
    RefreshTokenKey: 'appRefreshTokenMetabetAll',
    TokenKey: 'appTokenMetabetAll',
    GlobalMarketKey: 'SelectedGlobalMarketMetabetAll'
  }
}

export default AppConfig;
